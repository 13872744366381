<template>
  <div class="header py-0 shadow bg-white mt-3">
    <div class="container-fluid">
      <div class="header-body">
        <div class="row py-3">
          <nav aria-label="breadcrumb" class="ml-2">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item">
                <a href="#"><i class="fas fa-link"></i></a>
              </li>
              <li class="breadcrumb-item"><a href="#"></a></li>
              All Banks accounts for the business
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-2">
    <button
      class="mb-2 btn btn-default btn-sm"
      @click="$router.push({ name: 'Add Bank' })"
    >
      Add Bank
    </button>
    <div class="card shadow w-100 p-4">
      <loader v-if="!data_loaded" />
      <data-table
        v-if="data_loaded"
        id="collections_list"
        :options="dtb_options.extra_options"
        :actions="dtb_options.actions"
        :data="dtb_options.data"
        :columns="dtb_options.columns"
        :on_click="dtb_on_click"
      />
    </div>
  </div>
</template>

<script>
import DataTable from "@/components/Datatable";
import IndependentLoader from "../../components/IndependentDefaultLoader";
import {
  getDocs,
  collection,
  doc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { alert } from "../../utils/alertUtils";

export default {
  name: "Products",
  components: {
    "data-table": DataTable,
    loader: IndependentLoader,
  },
  data() {
    return {
      th: this,
      data_loaded: false,
      collections: [],
      //   user_profile: this.$store.getters.getUserProfile,
      dtb_options: {
        data: [],
        actions: [
          {
            btnText: "Delete",
            btnClass: "btn btn-white text-danger btn-sm",
            iconClass: "fas fa-trash",
            btnFn: (uuid) => {
              this.delete_collection(uuid);
            },
            btnParamName: "uuid",
          },
          {
            btnText: "Management",
            btnClass: "btn btn-white text-info btn-sm",
            btnFn: (uuid) => {
              this.$router.push({
                name: "Bank Management",
                params: { uuid },
              });
            },
            btnParamName: "uuid",
          },
        ],
        columns: [
          {
            title: "Name",
            data: "name",
          },
          {
            title: "Account Number",
            data: "accountNumber",
          },
        ],
        extra_options: {},
      },
    };
  },
  methods: {
    dtb_on_click: function (row) {
      var uuid = row.uuid;
      console.log(uuid);
    },

    delete_collection: function (uuid) {
      const ref = doc(db, "banks", uuid);
      deleteDoc(ref)
        .then(() => {
          this.dtb_options.data = [];
          this.fetch_banks();
          alert.success_center("Success");
        })
        .catch(() => {
          alert.error("Error occured in deleting carrier");
        });
    },

    fetch_banks: async function () {
      const productsRef = collection(db, "banks");
      const q = query(
        productsRef,
        where("user_id", "==", this.$store.getters.getUserId)
      );

      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        this.dtb_options.data = [];
        this.data_loaded = true;
      } else {
        querySnapshot.forEach((doc) => {
          let responseData = doc.data();
          responseData.uuid = doc.id;
          this.dtb_options.data.push(responseData);
          this.data_loaded = true;
        });
      }
    },
  },
  created() {
    this.fetch_banks();
  },
};
</script>

<style></style>
